<template>
  <tasks-table
    :options="options"
  />
</template>

<script>
// Components
import TasksTable from '../../components/tables/TasksTable.vue'

export default {
  name: 'AssemblingTasks',
  components: {
    TasksTable
  },
  computed: {
    options () {
      return {
        title: this.$t('Packing Tasks'),
        service: this.$service.assemblingTask,
        queue: this.$service.assemblingQueue,
        fields: ['order==at']
      }
    }
  }
}
</script>
